import { useDispatch } from "react-redux";
import { showPopup } from "../../../redux/clientDetails";
import { DisplayContact, DisplayDepartment } from "../../../types";
import styled from "styled-components";
import React, { useState } from "react";
import { Button, colors, H4 } from "@commonsku/styles";
import { ContactSummary } from "./ContactSummary";
import { SubPageHeader } from "../SubPageHeader";
import { SearchBar } from "../SearchBar";
import { searchContacts } from "../utils";
import useClientDetails from "../hooks/useClientDetails";
import { ToastContainer } from "react-toastify";
import { ShowInactive } from "../ShowInactive";

const ContactContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
`;

const Department = styled.div`
    display: flex;
    flex-direction: column;
`;

const DepartmentContacts = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
`;

const DepartmentHeader = styled(H4)`
    &&& {
        margin: 16px 0;
        font-size: 16px;
    }
`;

const HeaderButtons = styled.div`
    &&& {
        display: flex;
        flex-direction: row;
        margin-left: auto;
        gap: 16px;
        height: 40px;
    }
`;

const AddContactButton = styled(Button)`
    &&& {
        display: flex;
        align-items: center;
        line-height: 24px;
        font-size: 14px;
    }
`;

const LABEL_STYLES: React.CSSProperties = {
    marginBottom: "6px",
    marginRight: 0,
    fontSize: "14px",
    fontWeight: 400,
    color: colors.primary1[65],
};

const NoContacts = styled.div`
    &&& {
        display: flex;
        justify-content: center;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        margin-top: 2rem;
    }
`;

interface DepartmentGroups {
    [name: DisplayDepartment["name"]]: {
        contacts: DisplayContact[];
        ordering: DisplayDepartment["ordering"];
    };
}

export const ContactsPage = () => {
    const { contacts, client } = useClientDetails();
    const [searchQuery, setSearchQuery] = useState("");
    const [showInactive, setShowInactive] = useState(false);
    const dispatch = useDispatch();
    const contactList = searchContacts(searchQuery, contacts);

    const departmentGroups = contactList
        .filter((contact) => contact.active || showInactive)
        .reduce((groups: DepartmentGroups, contact) => {
            const department = contact.department;

            if (!groups[department.name]) {
                groups[department.name] = {
                    contacts: [],
                    ordering: department.ordering,
                };
            }

            groups[department.name].contacts.push(contact);
            return groups;
        }, {});

    const departmentGroupList = Object.entries(departmentGroups)
        .sort((a, b) => a[1].ordering - b[1].ordering);

    const onSearch = (e: React.FormEvent) => {
        e.preventDefault();

        const value = (e.target as HTMLInputElement).value;
        setSearchQuery(value);
    };

    const clearSearch = () => {
        setSearchQuery("");
    };

    return (
        <>
            <ContactContainer>
                <SubPageHeader title="Contacts" clientName={client.name}>
                    <HeaderButtons>
                        <SearchBar
                            query={searchQuery}
                            onChange={onSearch}
                            onClear={clearSearch}
                        />
                        <ShowInactive
                            checked={showInactive}
                            onChange={() => setShowInactive(!showInactive)}
                            labelStyle={LABEL_STYLES}
                        />
                        <AddContactButton onClick={() => dispatch(showPopup("create-contact"))}>
                            Add Contact
                        </AddContactButton>
                    </HeaderButtons>
                </SubPageHeader>
                {contactList.length > 0 ?
                    departmentGroupList.map(([name, { contacts }]) => (
                        <Department key={name}>
                            <DepartmentHeader>{name}</DepartmentHeader>
                            <DepartmentContacts>
                                {contacts.map((contact) => (
                                    <ContactSummary
                                        key={contact.id}
                                        contact={contact}
                                        cardColor={contact.active ? colors.primary1[10] : colors.neutrals[20]}
                                        textColor={contact.active ? colors.primary1.main : colors.neutrals[70]}
                                    />
                                ))}
                            </DepartmentContacts>
                        </Department>
                    ))
                    :
                    <NoContacts>
                        {contacts.length > 0
                            ? "No matching contacts found"
                            : `${client.name} has no contacts yet`
                        }
                    </NoContacts>
                }
            </ContactContainer>
            <ToastContainer
                autoClose={3000}
                hideProgressBar={true}
            />
        </>
    );
};
