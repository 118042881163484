import React, { useRef, useState } from "react";
import { DisplayAddress } from "../../../types";
import { Button, Col, DoubleArrowIcon, Popup, PopupHeader, XIcon as BaseXIcon, Text } from "@commonsku/styles";
import CreateAddressForm from "../../address/CreateAddressForm";
import styled from "styled-components";
import { VerticalScrollIndicator } from "../ScrollIndicator";
import { useScrollPopup } from "../hooks/useScrollPopup";

export const PopupContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
    width: 100%;
    overflow: hidden;
`;

export const ScrollContainer = styled.div`
    position: relative;
    flex-grow: 1;
    overflow-y: auto;
`;

export const FormContainer = styled.div`
    height: 100%;
    width: 100%;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const PopupFooter = styled.div`
    display: flex;
    flex-shrink: 0;
    height: 60px;
    flex-direction: row-reverse;
`;

export const PopupTitle = styled(Col)`
    text-align: left;
    align-self: center;
`;

export const PopupTitleButtonContainer = styled(Col)`
    text-align: right;
`;

export const XIcon = styled(BaseXIcon)`
    cursor: pointer;
`;

const DeleteText = styled(Text)`
    align-self: center;
    margin-right: 8px;
    cursor: pointer;
`;

export interface EditAddressFields {
    parent_type: 'CLIENT',
    parent_id: string,
    address_type: DisplayAddress['type'],
    address_name: string,
    address_company: string,
    address_line_1: string,
    address_line_2: string,
    address_line_3: string,
    address_line_4: string,
    address_country: string,
    address_state: string,
    address_city: string,
    address_postal: string,   
}

export interface EditAddressPopupProps {
    address: DisplayAddress;
    parent_id: string;
    parent_type?: string;
    onClosePopup: () => void;
    onEdit: (address: any) => void;
    onDelete: (address: DisplayAddress) => void;
}

export const EditAddressPopup = ({
    address,
    parent_id,
    parent_type='CLIENT',
    onClosePopup,
    onEdit,
    onDelete,
}: EditAddressPopupProps) => {
    const [form, setForm] = useState({});
    const [isFormValid, setFormValid] = useState(true);
    const [showErrorHighlight, setShowErrorHighlight] = useState(false);
    const ref = useRef();
    const {
        scrollRef,
        handleScroll,
        scrolledToTop,
        scrolledToBottom,
    } = useScrollPopup();

    const scrollToTop = () => {
        if (ref.current) {
            (ref.current as HTMLElement).scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const handleSubmit = () => {
        if (!isFormValid) {
            setShowErrorHighlight(true);
            scrollToTop();
            return false;
        }
        onEdit(form);
        onClosePopup();
    };

    return (
        <Popup
            onClose={onClosePopup}
            header={
                <PopupHeader xsStyle="flex-wrap: wrap-reverse;" smStyle="flex-wrap: wrap;">
                    <PopupTitle xs sm={5}>
                        <span className="title">Edit Address</span>
                    </PopupTitle>
                    <PopupTitleButtonContainer xs sm={7}>
                        <XIcon
                          size="large"
                          onClick={onClosePopup}
                        />
                    </PopupTitleButtonContainer>
                </PopupHeader>
            }
        >
            <PopupContent>
                <ScrollContainer>
                    <VerticalScrollIndicator $direction="up" $visible={!scrolledToTop}>
                        <DoubleArrowIcon direction="up"/>
                    </VerticalScrollIndicator>
                    <FormContainer ref={scrollRef} onScroll={handleScroll}>
                        <CreateAddressForm
                            ref={ref}
                            // @ts-ignore
                            parent_id={parent_id}
                            parent_type={parent_type}
                            setForm={setForm}
                            setFormValid={setFormValid}
                            showError={showErrorHighlight}
                            addressToUpdate={address}
                        />
                    </FormContainer>
                    <VerticalScrollIndicator $direction="down" $visible={!scrolledToBottom}>
                        <DoubleArrowIcon direction="down"/>
                    </VerticalScrollIndicator>
                </ScrollContainer>
                <PopupFooter>
                    <Button cta onClick={handleSubmit}>Save</Button>
                    <DeleteText color="error" onClick={() => onDelete(address)}>
                        Delete Address
                    </DeleteText>
                </PopupFooter>
            </PopupContent>
        </Popup>
    );
};
