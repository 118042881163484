import { useDispatch, useSelector } from "react-redux";
import { showPopup } from "../../../redux/clientDetails";
import { DisplayAddress, DisplayShippingAccount } from "../../../types";
import styled from "styled-components";
import React, { useState } from "react";
import { Button, colors } from "@commonsku/styles";
import { SubPageHeader } from "../SubPageHeader";
import { SearchBar } from "../SearchBar";
import { AddressSummary } from "./AddressSummary";
import useClientDetails from "../hooks/useClientDetails";
import { EditAddressFields, EditAddressPopup } from "../popups/EditAddressPopup";
import { toast, ToastContainer } from "react-toastify";
import { createDeleteAddress, createUpdateAddress } from "../../../actions/address";
import { createValidateConfirmationPopup } from "../../../actions/popup";
import { ShippingAccountSummary } from "./ShippingAccountSummary";
import { ShowInactive } from "../ShowInactive";

const ADDRESS_FIELDS_MAP: { [k in keyof DisplayAddress]?: keyof EditAddressFields } = {
    type: "address_type",
    name: "address_name",
    company: "address_company",
    address_line_1: "address_line_1",
    address_line_2: "address_line_2",
    address_line_3: "address_line_3",
    address_line_4: "address_line_4",
    country: "address_country",
    state: "address_state",
    city: "address_city",
    postal: "address_postal",
} as const;

const AddressContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
`;

const HeaderButtons = styled.div`
    &&& {
        display: flex;
        flex-direction: row;
        margin-left: auto;
        gap: 16px;
        height: 40px;
    }
`;

const AddAddressButton = styled(Button)`
    &&& {
        display: flex;
        align-items: center;
        line-height: 24px;
        font-size: 14px;
    }
`;

const SummaryCardContainer = styled.div`
    &&& {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 16px;
        margin-bottom: 2rem;
    }
`;

const NoSummaryCards = styled.div`
    &&& {
        display: flex;
        justify-content: center;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        margin-top: 2rem;
    }
`;

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    &&& {
        > * {
            margin-bottom: 0 !important;
        }
    }
`;

const LABEL_STYLES: React.CSSProperties = {
    marginBottom: "6px",
    marginRight: 0,
    fontSize: "14px",
    fontWeight: 400,
    color: colors.primary1[65],
};

const searchAddresses = (query: string, showInactive: boolean, addresses: readonly DisplayAddress[]) => {
    const relevantAddresses = showInactive 
        ? [...addresses]
        : addresses.filter((address) => address.active);

    // Sort by active status, then by name
    const sortedAddresses = relevantAddresses.sort((a, b) => {
        if (a.active === b.active) {
            return a.name.localeCompare(b.name);
        }

        return a.active ? -1 : 1;
    });

    if (query === "") {
        return sortedAddresses;
    }

    const lowerCaseQuery = query.toLocaleLowerCase();

    return sortedAddresses.filter((address) =>
        address.type.toLocaleLowerCase().includes(lowerCaseQuery) ||
        (address.name && address.name.toLocaleLowerCase().includes(lowerCaseQuery)) ||
        address.city.toLocaleLowerCase().includes(lowerCaseQuery) ||
        address.state.toLocaleLowerCase().includes(lowerCaseQuery) ||
        address.country.toLocaleLowerCase().includes(lowerCaseQuery) ||
        address.postal.toLocaleLowerCase().includes(lowerCaseQuery) ||
        address.address_line_1.toLocaleLowerCase().includes(lowerCaseQuery) ||
        address.address_line_2.toLocaleLowerCase().includes(lowerCaseQuery) ||
        address.address_line_3.toLocaleLowerCase().includes(lowerCaseQuery) ||
        address.address_line_4.toLocaleLowerCase().includes(lowerCaseQuery)
    );
}

const searchShippingAccounts = (query: string, shippingAccounts: readonly DisplayShippingAccount[]) => {
    if (query === "") {
        return shippingAccounts;
    }

    const lowerCaseQuery = query.toLocaleLowerCase();

    return shippingAccounts.filter((shippingAccount) =>
        shippingAccount.name.toLocaleLowerCase().includes(lowerCaseQuery) ||
        shippingAccount.number.toLocaleLowerCase().includes(lowerCaseQuery) ||
        shippingAccount.postal?.toLocaleLowerCase().includes(lowerCaseQuery) ||
        shippingAccount.courier.label.toLocaleLowerCase().includes(lowerCaseQuery)
    );
}

export const AddressesPage = () => {
    const { addresses, shippingAccounts, client } = useClientDetails();
    const baseAddresses = useSelector((s: any) => s.entities.addresses);
    const [searchQuery, setSearchQuery] = useState("");
    const [shippingAccountSearchQuery, setShippingAccountSearchQuery] = useState("");
    const [editingAddress, setEditingAddress] = useState<DisplayAddress | null>(null);
    const dispatch = useDispatch();
    const [showInactive, setShowInactive] = useState(false);
    const addressList = searchAddresses(searchQuery, showInactive, addresses);
    const shippingAccountList = searchShippingAccounts(shippingAccountSearchQuery, shippingAccounts);

    const editAddress = (address: DisplayAddress) => {
        setEditingAddress(address);
    }

    const deleteAddress = (address: DisplayAddress) => {
        const baseAddress = address.id in baseAddresses ? baseAddresses[address.id] : null;

        try {
            dispatch(createDeleteAddress(baseAddress));
            toast.success("Address deleted successfully");
        } catch (e) {
            toast.error("Error deleting address");
        } finally {
            setEditingAddress(null);
        }
    }

    const restoreAddress = (address: DisplayAddress) => {
        try {
            dispatch(createUpdateAddress(
                address.id,
                'active',
                address.active,
                true,
            ));
            toast.success("Address restored successfully");
        } catch (e) {
            toast.error("Error restoring address");
        }
    }

    const onDeleteAddress = (address: DisplayAddress) => {
        dispatch(createValidateConfirmationPopup(
            () => deleteAddress(address),
            [],
            "Delete Address",
            `Are you sure? '${address.name}' will be moved to Inactive.`,
        ))
    }

    const onRestoreAddress = (address: DisplayAddress) => {
        dispatch(createValidateConfirmationPopup(
            () => restoreAddress(address),
            [],
            "Restore Address",
            `Do you want to make '${address.name}' active again?`,
        ))
    }

    const createUpdateAddressCallback = (address: DisplayAddress) => {
        return (updatedFields: any) => {
            try {
                Object.entries(ADDRESS_FIELDS_MAP).forEach(([property, fieldName]) => {
                    if (updatedFields[fieldName] !== null && address[property] !== updatedFields[fieldName]) {
                        dispatch(createUpdateAddress(
                            address.id,
                            fieldName,
                            address[property],
                            updatedFields[fieldName],
                        ));
                    }
                });
            } catch (e) {
                toast.error("Error updating address"); 
            }
        }
    }

    const onSearch = (e: React.FormEvent) => {
        e.preventDefault();

        const value = (e.target as HTMLInputElement).value;
        setSearchQuery(value);
    };

    const clearSearch = () => {
        setSearchQuery("");
    };

    const onShippingAccountSearch = (e: React.FormEvent) => {
        e.preventDefault();

        const value = (e.target as HTMLInputElement).value;
        setShippingAccountSearchQuery(value);
    }

    const clearShippingAccountSearch = () => {
        setShippingAccountSearchQuery("");
    }

    return (
        <AddressContainer>
            {editingAddress && (
                <EditAddressPopup
                    address={editingAddress}
                    parent_id={client.id}
                    onClosePopup={() => setEditingAddress(null)}
                    onEdit={createUpdateAddressCallback(editingAddress)}
                    onDelete={onDeleteAddress}
                />
            )}
            <SubPageHeader title="Addresses" clientName={client.name}>
                <HeaderButtons>
                    <SearchBar
                        query={searchQuery}
                        onChange={onSearch}
                        onClear={clearSearch}
                    />
                    <ShowInactive
                        checked={showInactive}
                        onChange={() => setShowInactive(!showInactive)}
                        labelStyle={LABEL_STYLES}
                    />
                    <AddAddressButton onClick={() => dispatch(showPopup("create-address"))}>
                        Add Address
                    </AddAddressButton>
                </HeaderButtons>
            </SubPageHeader>
            {addressList.length > 0 ?
                <SummaryCardContainer>
                    {addressList.map((address) =>
                        <AddressSummary
                            key={address.id}
                            address={address}
                            onClick={address.active ? editAddress : onRestoreAddress}
                        />
                    )}
                </SummaryCardContainer>
                :
                <NoSummaryCards>
                    {addresses.length > 0
                        ? "No matching addresses found"
                        : `${client.name} has no addresses yet`
                    }
                </NoSummaryCards>
            }
            <ToastContainer
                autoClose={3000}
                hideProgressBar={true}
            />
            <SubPageHeader title="Shipping Accounts" clientName={client.name}>
                <HeaderButtons>
                    <SearchBar
                        query={shippingAccountSearchQuery}
                        onChange={onShippingAccountSearch}
                        onClear={clearShippingAccountSearch}
                    />
                    <AddAddressButton onClick={() => dispatch(showPopup("create-shipping-account"))}>
                        Add Shipping Account
                    </AddAddressButton>
                </HeaderButtons>
            </SubPageHeader>
            {shippingAccounts.length > 0 ?
                <SummaryCardContainer>
                    {shippingAccountList.map((shippingAccount) =>
                        <ShippingAccountSummary
                            key={shippingAccount.id}
                            shippingAccount={shippingAccount}
                        />
                    )}
                </SummaryCardContainer>
                :
                <NoSummaryCards>
                    {shippingAccounts.length > 0
                        ? "No matching shipping accounts found"
                        : `${client.name} has no shipping accounts yet`
                    }
                </NoSummaryCards>
            }
        </AddressContainer>
    );
};
