import React, { useRef, useState } from "react";
import {
  Button,
  Popup,
  PopupHeader,
  colors,
  DoubleArrowIcon,
} from "@commonsku/styles";
import { EditContactForm } from "../../contact/EditContactForm";
import { DisplayContact } from "../../../types";
import styled from "styled-components";
import {
  FormContainer,
  PopupContent,
  PopupFooter,
  PopupTitle,
  PopupTitleButtonContainer,
  ScrollContainer,
  XIcon,
} from "./EditAddressPopup";
import { VerticalScrollIndicator } from "../ScrollIndicator";
import { useScrollPopup } from "../hooks/useScrollPopup";

const ContactFormLink = styled.a`
  &&& {
    color: ${colors.primary1.main} !important;
    border-radius: 4px;
    border: 3px solid white;
    font-size: 0.875;
    font-weight: 600;
    line-height: 1.5rem;
    padding: 12px 25px;
    cursor: pointer;
    &:hover {
      background-color: ${colors.primary1[20]};
      border: 3px solid ${colors.primary1[20]};
    }
  }
`;
const StyledButton = styled(Button)<{ marginLeft?: boolean }>`
  &&& {
    ${(props) => (props.marginLeft ? "margin-left" : "margin-right")}: 5px;
  }
`;

interface EditContactPopupProps {
  contact: DisplayContact;
  parent_id: string;
  parent_type?: string;
  onClosePopup: () => void;
  onEdit: (contact: any) => void;
}

export const EditContactPopup = ({
  contact,
  parent_id,
  parent_type = "CLIENT",
  onClosePopup,
  onEdit,
}: EditContactPopupProps) => {
  const [form, setForm] = useState({});
  const [isFormValid, setFormValid] = useState(true);
  const [showErrorHighlight, setShowErrorHighlight] = useState(false);
  const contactFormRef = useRef();
  const { scrollRef, handleScroll, scrolledToTop, scrolledToBottom } =
    useScrollPopup();

  const scrollToTop = () => {
    if (contactFormRef.current) {
      (contactFormRef.current as HTMLElement).scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleSubmit = () => {
    if (!isFormValid) {
      setShowErrorHighlight(true);
      scrollToTop();
      return false;
    }
    onEdit(form);
    onClosePopup();
  };

  return (
    <Popup
      onClose={() => onClosePopup()}
      header={
        <PopupHeader
          xsStyle="flex-wrap: wrap-reverse;"
          smStyle="flex-wrap: wrap;"
        >
          <PopupTitle xs sm={5}>
            <span className="title">Edit Contact</span>
          </PopupTitle>
          <PopupTitleButtonContainer xs sm={7}>
            <XIcon size="large" onClick={onClosePopup} />
          </PopupTitleButtonContainer>
        </PopupHeader>
      }
    >
      <PopupContent>
        <ScrollContainer>
          <VerticalScrollIndicator $direction="up" $visible={!scrolledToTop}>
            <DoubleArrowIcon direction="up" />
          </VerticalScrollIndicator>
          <FormContainer ref={scrollRef} onScroll={handleScroll}>
            <EditContactForm
              ref={contactFormRef}
              contact={contact}
              parent_id={parent_id}
              parent_type={parent_type}
              showError={showErrorHighlight}
              setFormValid={setFormValid}
              setForm={setForm}
            />
          </FormContainer>
          <VerticalScrollIndicator
            $direction="down"
            $visible={!scrolledToBottom}
          >
            <DoubleArrowIcon direction="down" />
          </VerticalScrollIndicator>
        </ScrollContainer>
        <PopupFooter>
          <ContactFormLink
            href={`/contact/${contact.companyContactId}`}
            rel="opener noreferrer"
            target="_blank"
          >
            View Contact Page
          </ContactFormLink>
          <StyledButton marginLeft onClick={handleSubmit}>
            Save
          </StyledButton>
        </PopupFooter>
      </PopupContent>
    </Popup>
  );
};
