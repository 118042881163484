// Not part of MVP, will be added later
import React from "react";
import ArtworkLibrary from "../../artwork-library/ArtworkLibrary";
import { ArtworkLibraryProvider } from "../../artwork-library/context";
import useClientDetails from "../hooks/useClientDetails";
import { SubPageHeader } from "../SubPageHeader";
import styled from "styled-components";

const LibraryPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const LibraryPage = () => {
  const { client } = useClientDetails();
  return (
    <LibraryPageContainer>
      <ArtworkLibraryProvider
        initParents={[
          {
            parent_type: "CLIENT",
            parent_id: client.id,
          },
        ]}
        initParent={{
          parent_type: "CLIENT",
          parent_id: client.id,
        }}
        isModal={false}
        modalOpen={false}
        setModalOpen={false}
        onFileSelect={(f) => {}}
      >
        <ArtworkLibrary clientName={client.name} />
      </ArtworkLibraryProvider>
    </LibraryPageContainer>
  );
};
